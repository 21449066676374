<template>
    <div>
        <title-bar :title="$t('navigations.ocr')"/>
        <advanced-filter
            :fields-array="filterFields"
            :action="showFilterModal"
            @clearAction="showFilterModal = false"
            @paramsChange="setAdvancedParams"
        />
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'addBtn', 'perPage', 'advancedFilter']"
                :addBtnText="$t('messages.ocrCreate')"
                :addBtnLink="$helper.getAddUrl('ocr')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:referenceNumber="data">
                    {{ data.value.referenceNumber }}
                </template>
                <template v-slot:customer="data">
                   <span v-if="data.value.customer">
                     {{ data.value.customer.customer }}
                   </span>
                </template>
                <template v-slot:station="data">
                  <span v-if="data.value.station">
                    {{ data.value.station.name }}
                  </span>
                </template>
                <template v-slot:acRegistration="data">
                    {{ data.value.acRegistration }}
                </template>
                <template v-slot:manHoursUsed="data">
                    {{ data.value.manHoursUsed }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:technicalDelay="data">
                    <b-badge
                        :variant="delayClass(data.value.etd, data.value.timeOfCSRIssue, data.value.ata, data.value.eta)">
                        {{ technicalDelay(data.value.etd, data.value.timeOfCSRIssue, data.value.ata, data.value.eta) }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn :path="$helper.getEditUrl('ocr', data.value.id)"/>
                        <delete-btn @pressDelete="deleteOcr(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import {getUserData} from "@/auth/utils";

export default {
    name: "OcrListing",
    props: {
        widget: {type: Boolean, default: false}
    },
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            filterFields: [
                {
                    label: 'forms.created',
                    name: "createdAt",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.customer',
                    name: "customer",
                    value: null,
                    type: "customer"
                },
                {
                    label: 'forms.employee',
                    name: "employee",
                    value: null,
                    type: "employee"
                },
                {
                    label: 'forms.description',
                    name: "description",
                    value: null,
                    type: "text"
                },
                {
                    label: 'forms.station',
                    name: "station",
                    value: null,
                    type: "entity",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.acRegistration',
                    name: "acRegistration",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.dateOfAssistance',
                    name: "dateOfAssistance",
                    value: null,
                    type: "range"
                }
            ],
            advancedParams: [],
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.ocrNo"),
                    field: "referenceNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.customer"),
                    field: "customer",
                    sortable: true,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.acRegistration"),
                    field: "acRegistration",
                    sortable: false,
                },
                {
                    label: this.$t("forms.manHoursUsed"),
                    field: "manHoursUsed",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.technicalDelay"),
                    field: "technicalDelay",
                    sortable: false,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage
            };

            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                params['station'] = getUserData()['station']['id']
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$Ocrs.getCollection({params}, 'ocr_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            if (order === "station") {
                this.sortable.order = order + ".name";
            }
            if (order === "customer") {
                this.sortable.order = order + ".customer";
            }

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteOcr(url) {
            this.deleteByUrl(
                this.$Ocrs,
                url,
                this.$t("messages.ocrDeleted"),
                null,
                this.load,
                this.error
            );
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        technicalDelay(etd, timeOfCSRIssue, ata, eta) {
            let diff

            if (ata > eta) { // If ATA (actual arrival) is greater than ETA, we need more complex calculation
                diff = new Date(timeOfCSRIssue) - new Date(etd) - (new Date(ata) - new Date(eta));
            } else {
                diff = new Date(timeOfCSRIssue) - new Date(etd);
            }

            if (diff <= 0) {
                return this.$t('forms.noTechnicalDelay');
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);

            return hours + ' hours ' + minutes + ' minutes ';
        },
        delayClass(etd, timeOfCSRIssue, ata, eta) {
            let diff = new Date(timeOfCSRIssue) - new Date(etd) - (new Date(ata) - new Date(eta));
            if (diff <= 0) {
                return 'success';
            }
            let hours = Math.floor(diff / 1000 / 60 / 60);
            if (hours > 0) {
                return 'danger';
            }

            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);
            if (minutes > 0 && minutes < 15) {
                return 'warning';
            }
            return 'success';

        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>
